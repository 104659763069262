import React, { Fragment, FunctionComponent } from 'react'
import {
    isMultiSelectNavigator,
    isRangeNavigator,
    isSingleSelectNavigator,
    isTextNavigator,
} from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { NavigatorProps } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/NavigatorProps'
import { MultiSelectNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/MultiSelectNavigator/MultiSelectNavigator'
import { KeywordNavigator } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/KeywordNavigator/KeywordNavigator'
import { RangeNavigatorComponent } from '@bbx/search-journey/sub-domains/search/components/common/common/Navigators/RangeNavigatorComponent/RangeNavigatorComponent'
import { ProvinceNavigator } from '../../../../common/common/Navigators/ProvinceNavigator/ProvinceNavigator'
import { GeoNavigator } from './GeoNavigator'
import { Text } from '@wh-components/core/Text/Text'
import { Navigator, RangeNavigator } from '@bbx/search-journey/common/Navigators'
import { SearchId } from '@bbx/common/api/dtos/search-id'
import { verticals } from '@wh/common/chapter/types/verticals'
import { SearchIdNavigator } from '@bbx/search-journey/sub-domains/search/components/common/result-list/SearchIdNavigator/SearchIdNavigator'

export const RealEstateNavigatorDiscriminator: FunctionComponent<
    NavigatorProps & { abortRequest: () => void; districtNavigator: Navigator | RangeNavigator | undefined }
> = ({ navigator, districtNavigator, onSearch, abortRequest, searchId, 'aria-labelledby': ariaLabelledBy, taggingData }) => {
    if (navigator.id === 'province') {
        return (
            <ProvinceNavigator
                navigator={navigator}
                districtNavigator={districtNavigator}
                onSearch={onSearch}
                label="Suche in Region"
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
                taggingEvent="search_result_list_show_all_regions"
                vertical={verticals.ESTATE}
            />
        )
    } else if (navigator.id === 'searchId') {
        return <SearchIdNavigator navigator={navigator} onSearch={onSearch} taggingData={taggingData} />
    } else if (navigator.id === 'district') {
        return null
    } else if (navigator.id === 'location-autocomplete') {
        return (
            <Fragment>
                <Text display="inline-block" fontSize="s" fontStyle="italic" color="palette.elephant" marginBottom="s">
                    oder
                </Text>
                <GeoNavigator
                    navigator={navigator}
                    onSearch={onSearch}
                    label="direkt nach Orten"
                    aria-labelledby={ariaLabelledBy}
                    taggingData={taggingData}
                    taggingEvent="search_result_list_filter_autocomplete_click"
                    shownInResultList={true}
                />
            </Fragment>
        )
    } else if (isTextNavigator(navigator)) {
        return (
            <KeywordNavigator
                navigator={navigator}
                onSearch={onSearch}
                abortRequest={abortRequest}
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
                {...(searchId === SearchId.New_Constructions && { placeholder: 'z. B. Projektname' })}
            />
        )
    } else if (isSingleSelectNavigator(navigator) || isMultiSelectNavigator(navigator)) {
        return <MultiSelectNavigator navigator={navigator} onSearch={onSearch} aria-labelledby={ariaLabelledBy} taggingData={taggingData} />
    } else if (isRangeNavigator(navigator)) {
        return (
            <RangeNavigatorComponent
                navigator={navigator}
                abortRequest={abortRequest}
                onSearch={onSearch}
                aria-labelledby={ariaLabelledBy}
                taggingData={taggingData}
                searchId={searchId}
            />
        )
    } else {
        return null
    }
}
