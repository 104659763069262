import React, { FunctionComponent } from 'react'
import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import { Box } from '@wh-components/core/Box/Box'
import { css } from 'styled-components'
import { PossibleValueLinkVomit } from '@bbx/search-journey/sub-domains/search/components/common/common/PossibleValueLinkVomit/PossibleValueLinkVomit'
import { SearchId } from '@bbx/common/api/dtos/search-id'
import { getFlatPossibleValues, getNavigatorById } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { SeoSearchTerms } from '@bbx/search-journey/common/SeoSearchTerms'
import { SearchTermsLinkVomit } from '@bbx/search-journey/common/components/SearchTermsLinkVomit/SearchTermsLinkVomit'

export interface RealEstateLinkVomitProps {
    currentSearchResult: SearchResult
    searchTerms: SeoSearchTerms
}

export const RealEstateLinkVomit: FunctionComponent<RealEstateLinkVomitProps> = ({ currentSearchResult, searchTerms }) => {
    const provinceNavigator = getNavigatorById(currentSearchResult, 'province')
    const districtNavigator = getNavigatorById(currentSearchResult, 'district')
    const webLinksAreLive = !!provinceNavigator?.groupedPossibleValues[0].possibleValues[0].webLink
    const provincePossibleValues = provinceNavigator ? getFlatPossibleValues(provinceNavigator) : null
    const showDistrictLinkVomit = provinceNavigator?.selectedValues.length === 1
    const districtPossibleValues = districtNavigator ? getFlatPossibleValues(districtNavigator) : null
    const isNotSonstige = currentSearchResult.searchId !== SearchId.RealEstateMisc

    return (
        <Box
            paddingBottom={{ phone: 'm', tablet: 0 }}
            borderTop={{ phone: 'owl', tablet: 0 }}
            css={css`
                > *:last-child {
                    padding-bottom: 0;
                }
            `}
        >
            {webLinksAreLive && isNotSonstige && showDistrictLinkVomit && districtPossibleValues && (
                <Box paddingVertical={{ tablet: 'm' }} borderTop={{ tablet: 'owl' }}>
                    <PossibleValueLinkVomit title="Suche nach Regionen" possibleValues={districtPossibleValues} />
                </Box>
            )}
            {webLinksAreLive && isNotSonstige && provincePossibleValues && (
                <Box paddingVertical={{ tablet: 'm' }} borderTop={{ tablet: 'owl' }}>
                    <PossibleValueLinkVomit
                        title={`${searchIdHeading[currentSearchResult.searchId] ?? 'Immobilien'} in Österreich`}
                        possibleValues={provincePossibleValues}
                    />
                </Box>
            )}
            <Box paddingVertical={{ tablet: 'm' }} borderTop={{ tablet: 'owl' }}>
                <SearchTermsLinkVomit searchTermsList={[searchTerms]} />
            </Box>
        </Box>
    )
}

const searchIdHeading: Record<number, string | undefined> = {
    [SearchId.Flat_Rent]: 'Wohnung mieten',
    [SearchId.Flat_Sale]: 'Wohnung kaufen',
    [SearchId.House_Rent]: 'Haus mieten',
    [SearchId.House_Sale]: 'Haus kaufen',
    [SearchId.Leisure_Rent]: 'Ferienimmobilie mieten',
    [SearchId.Leisure_Sale]: 'Ferienimmobilie kaufen',
    [SearchId.Commercial_Rent]: 'Gewerbeimmobilie mieten',
    [SearchId.Commercial_Sale]: 'Gewerbeimmobilie kaufen',
    [SearchId.Plots]: 'Grundstück kaufen/pachten',
    [SearchId.RealEstateMisc]: 'Sonstige Immobilien',
    [SearchId.RealEstateCommon]: 'Immobilien',
    [SearchId.New_Constructions]: 'Neubauprojekte',
}
