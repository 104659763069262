import React, { Fragment, FunctionComponent } from 'react'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { css } from 'styled-components'
import { SkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import {
    linkedUnitsImageHeight,
    NcAdvertSummaryComponent,
} from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/NcAdvertSummaryComponent/NcAdvertSummaryComponent'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { findContextLinkWithId } from '@bbx/common/types/contextLinks'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { scrollToTop } from '@wh/common/chapter/lib/commonHelpers'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { HorizontalSlider } from '@wh/common/chapter/components/HorizontalSlider/HorizontalSlider'

interface NcLinkedUnitsProps {
    advertSummary: AdvertSummary
    triggerSearch: SearchCallback
    taggingData: TaggingData
}

export const NcLinkedUnits: FunctionComponent<NcLinkedUnitsProps> = ({ advertSummary, triggerSearch, taggingData }) => {
    const linkedUnits = advertSummary.children
    const numberOfChildren = getAttributeValue(advertSummary.attributes.attribute, 'NUMBER_OF_CHILDREN')
    const childrenLink = findContextLinkWithId('childAdsLink', advertSummary.contextLinkList)

    return linkedUnits && linkedUnits.length > 0 ? (
        <HorizontalSlider
            heading={
                <Text fontWeight="bold" fontSize="s">
                    {numberOfChildren === '1' ? `${numberOfChildren} passende Anzeige` : `${numberOfChildren} passende Anzeigen`}
                </Text>
            }
            linkText="Alle anzeigen"
            buttonText="Alle anzeigen"
            onClick={async () => {
                if (!childrenLink) {
                    return
                }
                scrollToTop()
                await triggerSearch(childrenLink)
            }}
            testIdPrefix={`nc-linked-units-${advertSummary.id}`}
            numberOfAdsToDisplay={4}
            numberOfAdsLimit={7}
            marginTop="s"
            paddingLeft="m"
            paddingRight={{ desktop: 'm' }}
            buttonHeight={linkedUnitsImageHeight}
            css={css`
                ${(p) => p.theme.media.only.tablet} {
                    margin-right: -${(p) => p.theme.space.m}px;
                }
            `}
        >
            {linkedUnits.slice(0, 9).map((summary) => (
                <LinkedUnit key={summary.id} linkedUnit={summary} taggingData={taggingData} />
            ))}
        </HorizontalSlider>
    ) : null
}

const LinkedUnit: FunctionComponent<{ linkedUnit: AdvertSummary; taggingData: TaggingData }> = ({ linkedUnit, taggingData }) => {
    return (
        <Box
            css={css`
                z-index: ${(p) => p.theme.zIndices.content};
                &:hover {
                    background-color: ${(p) => p.theme.colors.palette.babyseal};
                }
            `}
        >
            <NcAdvertSummaryComponent
                advertSummary={linkedUnit}
                onClickTaggingEvent={() =>
                    callActionEvent('real_estate_result_list_nc_object_click', taggingData, { ad_id: linkedUnit.id })
                }
            />
        </Box>
    )
}

export const LinkedUnitListSkeleton: FunctionComponent = () => (
    <HorizontalSlider isLoading={true} testIdPrefix="linked-units-skeleton" numberOfAdsToDisplay={4}>
        <LinkedUnitSkeleton />
        <LinkedUnitSkeleton />
        <LinkedUnitSkeleton />
        <LinkedUnitSkeleton />
    </HorizontalSlider>
)

const LinkedUnitSkeleton: FunctionComponent = () => {
    return (
        <Fragment>
            <Box height={{ phone: 108, tablet: 134 }} width="100%" marginBottom="xs">
                <SkeletonLine />
            </Box>
            <Box width="25%" height={18} marginBottom="xs">
                <SkeletonLine />
            </Box>
            <Box width="80%" height={17} marginBottom="xs">
                <SkeletonLine />
            </Box>
            <Box width="60%" height={17} marginBottom="xs">
                <SkeletonLine />
            </Box>
        </Fragment>
    )
}
