import { SearchResult } from '@bbx/search-journey/common/SearchResult'
import React, { FunctionComponent } from 'react'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { getAttributeValue } from '@wh/common/chapter/types/Attributes'
import { ResultListAdInMotionSkeleton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdInMotionSkeleton'
import { css } from 'styled-components'
import { scrollMargin } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdRows'
import { ResultListAdRowSkeleton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdRowSkeleton'
import { AdRowDynamicRendering } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRowDynamicRendering'
import { RealEstateAdInMotion } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/AdRow/RealEstateAdInMotion'
import { NcRealEstateAdRowSkeleton } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/AdRow/NcRealEstateAdRowSkeleton'
import { NcRealEstateAdRow } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/AdRow/NcRealEstateAdRow'
import { RealEstateAdRow } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/AdRow/RealEstateAdRow'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { baseFontSize } from '@wh-components/core/theme/fontSizeHelper'

export const InternalRealEstateAdRowWrapper: FunctionComponent<{
    currentSearchResult: SearchResult
    triggerSearch: SearchCallback
    isLoading: boolean
    index: number
    advertSummary: AdvertSummary
}> = ({ currentSearchResult, isLoading, triggerSearch, index, advertSummary }) => {
    const isAdinMotion = getAttributeValue(advertSummary.attributes.attribute, 'UPSELLING_RESULT_LIST_AD_IN_MOTION')

    const isNcAdRow = getAttributeValue(advertSummary.attributes.attribute, 'ADTYPE_ID') === '16'

    const adInMotionHeight = { phone: 405, tablet: 458, desktop: 502 }
    if (isAdinMotion) {
        return (
            <AdRowDynamicRendering index={index} id={advertSummary.id} nrOfAds={5} height={adInMotionHeight}>
                {isLoading ? (
                    <ResultListAdInMotionSkeleton
                        height={adInMotionHeight}
                        css={css`
                            ${scrollMargin}
                        `}
                    />
                ) : (
                    <RealEstateAdInMotion
                        advertSummary={advertSummary}
                        taggingData={currentSearchResult.taggingData}
                        css={css`
                            ${scrollMargin}
                        `}
                    />
                )}
            </AdRowDynamicRendering>
        )
    } else if (isNcAdRow) {
        return (
            <AdRowDynamicRendering index={index} id={advertSummary.id} nrOfAds={5} height={{ phone: 504, tablet: 581, desktop: 583 }}>
                {isLoading ? (
                    <NcRealEstateAdRowSkeleton
                        css={css`
                            ${scrollMargin}
                            content-visibility: auto;
                            contain-intrinsic-size: 483px;

                            ${(p) => p.theme.media.tablet} {
                                contain-intrinsic-size: 577px;
                            }
                        `}
                    />
                ) : (
                    <NcRealEstateAdRow
                        triggerSearch={triggerSearch}
                        advertSummary={advertSummary}
                        taggingData={currentSearchResult.taggingData}
                        css={css`
                            ${scrollMargin}
                        `}
                    />
                )}
            </AdRowDynamicRendering>
        )
    } else {
        return (
            <AdRowDynamicRendering
                index={index}
                id={advertSummary.id}
                nrOfAds={5}
                height={{ phone: 233, desktop: `${182 / baseFontSize}rem` }}
            >
                {isLoading ? (
                    <ResultListAdRowSkeleton
                        estimatedPhoneTitleLines={2}
                        css={css`
                            ${scrollMargin}
                            content-visibility: auto;
                            contain-intrinsic-size: 165px;

                            ${(p) => p.theme.media.tablet} {
                                contain-intrinsic-size: 183px;
                            }
                        `}
                    />
                ) : (
                    <RealEstateAdRow
                        advertSummary={advertSummary}
                        taggingData={currentSearchResult.taggingData}
                        css={css`
                            ${scrollMargin}
                        `}
                    />
                )}
            </AdRowDynamicRendering>
        )
    }
}

export const RealEstateAdRowWrapper = React.memo(InternalRealEstateAdRowWrapper)
