import React, { FunctionComponent } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { BrightSkeletonLine } from '@wh/common/chapter/components/Skeletons/Skeletons'
import { LinkedUnitListSkeleton } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/NcLinkedUnits/NcLinkedUnits'
import { css } from 'styled-components'

export const NcRealEstateAdRowSkeleton: FunctionComponent<{ className?: string }> = ({ className }) => (
    <Box padding="m" display="flex" flexDirection="column" borderBottom="owl" className={className}>
        <Box width="100%" marginBottom="s" display="flex" flexDirection="column">
            <Box height={25} width="100%" marginBottom="xs">
                <BrightSkeletonLine />
            </Box>
            <Box height={20} width="40%" marginBottom="xs">
                <BrightSkeletonLine />
            </Box>
            <Box height={25} width="60%" marginBottom="xs">
                <BrightSkeletonLine />
            </Box>
        </Box>
        <Box
            display="flex"
            // Different tablet height than normal ad row is just to account for general differences
            // between skeleton and normal ad row and to ensure both are exactly the same height to remove jumping
            height={{ phone: '160px', tablet: '228px' }}
            width={{ tablet: '100%' }}
            marginBottom="xs"
            css={css`
                box-sizing: border-box;

                ${(p) => p.theme.media.only.phone} {
                    margin-right: -${(p) => p.theme.space.m}px;
                    margin-left: -${(p) => p.theme.space.m}px;
                }
            `}
        >
            <BrightSkeletonLine />
        </Box>
        <LinkedUnitListSkeleton />
    </Box>
)
