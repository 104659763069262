import { FormattedAttributesItem } from '@wh/common/chapter/types/Attributes'
import { topAdAddressConfig, TopAdConfig } from '../../../../common/result-list/TopAds/topAdConfig'
import { AttributeName } from '@wh/common/chapter/types/AttributeName'

const buildStringFromTeaserItems = ([size, rooms]: (FormattedAttributesItem | undefined)[]) => {
    const teaserAttributes: string[] = []
    if (size) {
        const formattedSize = `${size.values[0]} ${size.postfix}`
        teaserAttributes.push(formattedSize)
    }
    if (rooms) {
        const formattedRooms = `${rooms.values[0]} Zimmer`
        teaserAttributes.push(formattedRooms)
    }
    return teaserAttributes.join(', ')
}

const buildStringFromPriceItems = (prices: (FormattedAttributesItem | undefined)[]) => {
    const firstPrice = prices.find(Boolean) ?? prices[0]
    const formattedValues = firstPrice?.formattedValues
    return formattedValues ? formattedValues[0] : ''
}

const priceAttributes: AttributeName[] = ['PRICE_FOR_DISPLAY']

const topAdRealEstateGlobalConfig = {
    buildStringFromTeaserItems,
    buildStringFromPriceItems,
    priceAttributes,
    ...topAdAddressConfig,
}

type EstateAdType =
    | 'EstateSale'
    | 'EstateRent'
    | 'EstateBusinessSale'
    | 'EstateBusinessRent'
    | 'EstateLeisureSale'
    | 'EstateLeisureRent'
    | 'EstatePlotSale'
    | 'EstateMisc'

export const topAdRealEstateConfigMap: Record<EstateAdType, TopAdConfig> = {
    EstateSale: {
        teaserAttributes: ['ESTATE_SIZE/LIVING_AREA', 'NO_OF_ROOMS'],
        ...topAdRealEstateGlobalConfig,
    },
    EstateRent: {
        teaserAttributes: ['ESTATE_SIZE/LIVING_AREA', 'NO_OF_ROOMS'],
        ...topAdRealEstateGlobalConfig,
    },
    EstateBusinessSale: {
        teaserAttributes: ['ESTATE_SIZE/USEABLE_AREA', 'NO_OF_ROOMS'],
        ...topAdRealEstateGlobalConfig,
    },
    EstateBusinessRent: {
        teaserAttributes: ['ESTATE_SIZE/USEABLE_AREA', 'NO_OF_ROOMS'],
        ...topAdRealEstateGlobalConfig,
    },
    EstateLeisureSale: {
        teaserAttributes: ['ESTATE_SIZE/LIVING_AREA', 'NO_OF_ROOMS'],
        ...topAdRealEstateGlobalConfig,
    },
    EstateLeisureRent: {
        teaserAttributes: ['ESTATE_SIZE/LIVING_AREA', 'NO_OF_ROOMS'],
        ...topAdRealEstateGlobalConfig,
    },
    EstatePlotSale: {
        teaserAttributes: ['PLOT/AREA'],
        ...topAdRealEstateGlobalConfig,
    },
    EstateMisc: {
        teaserAttributes: ['ESTATE_SIZE/GROSS_AREA'],
        ...topAdRealEstateGlobalConfig,
    },
}
