import { AdvertStatus } from '@bbx/common/types/ad-detail/AdvertDetails'
import { FormattedAttributesList, getAttributeValue, getFormattedAttributeValue } from '@wh/common/chapter/types/Attributes'
import { adTypeIdMap } from '@wh/common/chapter/types/AdType'
import { getPriceAttribute, getPriceByStatus } from '@bbx/search-journey/common/lib/getAdvertPrice'
import { Price } from '@bbx/common/types/Price'
import { AttributeName } from '@wh/common/chapter/types/AttributeName'

type RealEstatePrices = { mainPrice: Price; secondaryPrice?: Price; additionalPrices?: Price[] }

export const getRealEstateAdvertPrice = (
    adType: number,
    adStatus: AdvertStatus | null,
    formattedAttributes: FormattedAttributesList,
): RealEstatePrices => {
    const price = getFormattedAttributeValue(formattedAttributes.attribute, 'PRICE_FOR_DISPLAY') || 'Keine Angabe'

    const ownageType = getAttributeValue(formattedAttributes.attribute, 'OWNAGETYPE')
    const plotOwned = getAttributeValue(formattedAttributes.attribute, 'PLOT/OWNED')
    const additionalCostGross = getPriceAttribute(formattedAttributes.attribute, 'RENTAL_PRICE/ADDITIONAL_COST_GROSS')
    const additionalCostNet = getPriceAttribute(formattedAttributes.attribute, 'RENTAL_PRICE/ADDITIONAL_COST_NET')
    const monthlyCostGross = getPriceAttribute(formattedAttributes.attribute, 'ESTATE_PRICE/MONTHCOSTS_GROSS')
    const securityDeposit = getPriceAttribute(formattedAttributes.attribute, 'ADDITIONAL_COST/DEPOSIT')
    const oneOffPayment = getPriceAttribute(formattedAttributes.attribute, 'RENTAL_PRICE/FURNITURE_COST')
    const totalEncumbrance = getPriceAttribute(formattedAttributes.attribute, 'RENTAL_PRICE/TOTAL_ENCUMBRANCE')

    switch (adType) {
        case adTypeIdMap.ESTATE_SALE: // Haus/Wohnung kaufen
            return {
                mainPrice: getPriceByStatus({ label: 'Kaufpreis', value: price }, adStatus),
                additionalPrices: [additionalCostGross, monthlyCostGross].filter((p) => Boolean(p.value)),
            }
        case adTypeIdMap.ESTATE_RENT: // Haus/Wohnung mieten
            return {
                mainPrice: getPriceByStatus({ label: 'Gesamtmiete inkl. MWSt', value: price }, adStatus),
                additionalPrices: [totalEncumbrance, securityDeposit].filter((p) => Boolean(p.value)),
            }
        case adTypeIdMap.ESTATE_BUSINESS_SALE: // Gewerbeimmobilie kaufen
            return {
                mainPrice: getPriceByStatus({ label: 'Kaufpreis', value: price }, adStatus),
                additionalPrices: [additionalCostNet, oneOffPayment].filter((p) => Boolean(p.value)),
            }
        case adTypeIdMap.ESTATE_BUSINESS_RENT: // Gewerbeimmobilie mieten
            return {
                mainPrice: getPriceByStatus({ label: 'Gesamtmiete', value: price }, adStatus),
                additionalPrices: [additionalCostGross, totalEncumbrance].filter((p) => Boolean(p.value)),
            }
        case adTypeIdMap.ESTATE_LEISURE_SALE: // Ferienimmobilie kaufen
            return { mainPrice: getPriceByStatus({ label: 'Kaufpreis', value: price }, adStatus) }
        case adTypeIdMap.ESTATE_LEISURE_RENT: // Ferienimmobilie mieten
            return {
                mainPrice: getPriceByStatus({ label: 'Miete pro Woche', value: price }, adStatus),
                additionalPrices: [securityDeposit].filter((p) => Boolean(p.value)),
            }
        case adTypeIdMap.ESTATE_NEW_CONSTRUCTION: // Neubauprojekt
            return getNcPrices(formattedAttributes, adStatus)
        case adTypeIdMap.ESTATE_PLOT_SALE: // Grundstück kaufen/pachten
            if (plotOwned === 'Pacht') {
                return { mainPrice: getPriceByStatus({ label: 'Pacht', value: price }, adStatus) }
            } else {
                return { mainPrice: getPriceByStatus({ label: 'Kaufpreis', value: price }, adStatus) }
            }
        case adTypeIdMap.ESTATE_MISC: // Sonstige Immobilie kaufen/mieten
            if (ownageType === 'Miete') {
                return {
                    mainPrice: getPriceByStatus({ label: 'Mietpreis', value: price }, adStatus),
                    additionalPrices: [securityDeposit, oneOffPayment].filter((p) => Boolean(p.value)),
                }
            } else {
                return {
                    mainPrice: getPriceByStatus({ label: 'Kaufpreis', value: price }, adStatus),
                    additionalPrices: [oneOffPayment].filter((p) => Boolean(p.value)),
                }
            }
        default:
            return { mainPrice: { label: 'Preis', value: price } }
    }
}

const getNcPrices = (formattedAttributes: FormattedAttributesList, adStatus: AdvertStatus | null): RealEstatePrices => {
    const ncPrices = (['PROJECT/UNIT_PRICE_FROM', 'PROJECT/UNIT_RENT_FROM'] as AttributeName[])
        .map((key) => getPriceAttribute(formattedAttributes.attribute, key))
        .filter((p) => Boolean(p.value))
        .map((p) => ({
            ...p,
            value: `ab ${getPriceByStatus({ value: p.value }, adStatus).value}`,
        }))

    return {
        mainPrice: ncPrices?.[0] ?? { value: '' },
        secondaryPrice: ncPrices?.[1],
    }
}
