import React, { FunctionComponent } from 'react'
import { Badge } from '@wh-components/core/Badge/Badge'
import { css } from 'styled-components'

export const NcBadge: FunctionComponent<{ id: string }> = ({ id }) => (
    <Badge
        fontSize="xs"
        fontWeight="bold"
        variant="outline"
        lineHeight="1"
        testId={`${id}-nc-badge`}
        marginBottom="xxs"
        css={css`
            flex-shrink: 0;
            vertical-align: middle;
        `}
    >
        Neubauprojekt
    </Badge>
)
