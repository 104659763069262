import React, { Fragment, FunctionComponent } from 'react'
import { css } from 'styled-components'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { Box } from '@wh-components/core/Box/Box'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { ResultListSaveAdButton } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListSaveAdButton'
import { getSeoUrl } from '@bbx/search-journey/common/lib/getSeoUrl'
import { NcLinkedUnits } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/NcLinkedUnits/NcLinkedUnits'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { adFocusHoverStyles } from '@wh/common/chapter/components/adFocusHoverStyles'
import { Text } from '@wh-components/core/Text/Text'
import { NcBadge } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/AdRow/NcBadge'
import { AdRowAddressLine } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/AdRow/AdRowAddressLine'
import { AdRowOrganisationLine } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/AdRow/AdRowOrganisationLine'
import { formatAttributes } from '@bbx/common/lib/formattingAttributes'
import { FormattedAttributesList, getFormattedAttributeValue } from '@wh/common/chapter/types/Attributes'
import { AdvertImage } from '@wh/common/chapter/types/AdvertImage'
import { ResponsiveValue, SystemValue } from '@wh-components/system'
import { callActionEvent } from '@wh/common/chapter/lib/tagging/tagging'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'

interface NcRealEstateAdRowProps {
    advertSummary: AdvertSummary
    taggingData: TaggingData
    triggerSearch: SearchCallback
}

export const NcRealEstateAdRow: FunctionComponent<NcRealEstateAdRowProps> = ({ advertSummary, taggingData, triggerSearch }) => {
    const link = getSeoUrl(advertSummary)

    const formattedAttributes = formatAttributes(advertSummary.attributes)
    return (
        <Box position="relative" borderBottom="owl" aria-label={advertSummary.description} paddingBottom="m">
            <Box id={advertSummary.id} position="relative" paddingHorizontal="m" paddingTop="m" css={adFocusHoverStyles()}>
                <ClientRoutingAnchorLink
                    display="flex"
                    flexDirection="column"
                    type="anchor"
                    href={link}
                    onClick={() => callActionEvent('real_estate_result_list_nc_project_click', taggingData, { ad_id: advertSummary.id })}
                    color="palette.verydarkgrey"
                    underline="none"
                    testId={`search-result-entry-header-${advertSummary.id}`}
                    css={css`
                        outline: 0;
                    `}
                >
                    <NcRealEstateAdRowHeader advertSummary={advertSummary} formattedAttributes={formattedAttributes} />
                    <NcRealEstateAdRowImages advertSummary={advertSummary} />
                </ClientRoutingAnchorLink>
                <ClientRoutingAnchorLink
                    type="button"
                    size="small"
                    testId={`search-result-entry-link-to-project-${advertSummary.id}`}
                    href={link}
                    onClick={() => callActionEvent('real_estate_result_list_nc_project_click', taggingData, { ad_id: advertSummary.id })}
                    variant="outline"
                    position="absolute"
                    right={{ phone: 'm', tablet: 'xl' }}
                    bottom="m"
                    zIndex="2"
                    tabIndex={-1}
                >
                    zum Projekt
                </ClientRoutingAnchorLink>
            </Box>
            <ResultListSaveAdButton
                adId={advertSummary.id}
                adTitle={advertSummary.description}
                taggingData={taggingData}
                marginLeft="m"
                testId={`search-result-entry-save-ad-${advertSummary.id}`}
                position="absolute"
                right="m"
                top="m"
                zIndex="popover"
            />
            <NcLinkedUnits triggerSearch={triggerSearch} advertSummary={advertSummary} taggingData={taggingData} />
        </Box>
    )
}

const NcRealEstateAdRowHeader: FunctionComponent<{ advertSummary: AdvertSummary; formattedAttributes: FormattedAttributesList }> = ({
    advertSummary,
    formattedAttributes,
}) => {
    return (
        <Box width="100%" marginBottom="s" display="flex" position="relative">
            <Box color="palette.verydarkgrey" display="flex" flexDirection="column" minWidth={0} flex={1}>
                <NcRealEstateAdRowHeaderTitle advertSummary={advertSummary} />
                <NcRealEstateAdRowHeaderSubtitle advertSummary={advertSummary} formattedAttributes={formattedAttributes} />
            </Box>
        </Box>
    )
}

const NcRealEstateAdRowHeaderTitle: FunctionComponent<{ advertSummary: AdvertSummary }> = ({ advertSummary }) => {
    return (
        <Box display="flex" marginBottom="xs" alignItems="center">
            <Text as="h3" fontSize="l" fontWeight="normal" lineClamp={2}>
                <span
                    css={css`
                        margin-right: ${(p) => p.theme.space.xs}px;
                    `}
                >
                    {advertSummary.description}
                </span>
                <NcBadge id={advertSummary.id} />
            </Text>

            <Box marginLeft="50px" />
        </Box>
    )
}

const NcRealEstateAdRowHeaderSubtitle: FunctionComponent<{
    advertSummary: AdvertSummary
    formattedAttributes: FormattedAttributesList
}> = ({ advertSummary, formattedAttributes }) => {
    const price = getFormattedAttributeValue(formattedAttributes.attribute, 'PRICE_FOR_DISPLAY')

    return (
        <Box display="flex" flexDirection="column">
            <AdRowAddressLine id={advertSummary.id} attributes={formattedAttributes} />
            <Box display="flex" alignItems="flex-end" justifyContent="space-between" overflow="hidden">
                <AdRowOrganisationLine
                    id={advertSummary.id}
                    name={advertSummary.advertiserInfo?.label || undefined}
                    logo={advertSummary.upsellingOrganisationLogo || undefined}
                    marginTop="xs"
                    marginRight="s"
                />
                {price && (
                    <Text
                        fontSize={{ phone: 'xl', desktop: 'xxl' }}
                        fontWeight="bold"
                        lineHeight={1}
                        color="palette.primary.main"
                        flexShrink={0}
                        testId={`search-result-entry-price-${advertSummary.id}`}
                    >
                        {price}
                    </Text>
                )}
            </Box>
        </Box>
    )
}

const NcRealEstateAdRowImages: FunctionComponent<{
    advertSummary: AdvertSummary
}> = ({ advertSummary }) => {
    const imagePrimary = advertSummary.advertImageList.advertImage[0]
    const imageSecondary = advertSummary.advertImageList.advertImage[1]
    return (
        <Box
            display="flex"
            position="relative"
            css={css`
                box-sizing: border-box;

                ${(p) => p.theme.media.only.phone} {
                    margin-right: -${(p) => p.theme.space.m}px;
                    margin-left: -${(p) => p.theme.space.m}px;
                }
            `}
        >
            <Box
                color="palette.verydarkgrey"
                display="flex"
                height={{ phone: '160px', tablet: '223px' }}
                width={{ tablet: '100%' }}
                minWidth={0}
                flex={1}
            >
                <NcRealEstateAdRowImageBox image={imagePrimary} advertSummary={advertSummary} paddingRight="xxs" imageNumber={1} />
                <NcRealEstateAdRowImageBox image={imageSecondary} advertSummary={advertSummary} imageNumber={2} />
            </Box>
        </Box>
    )
}

const NcRealEstateAdRowImageBox: FunctionComponent<{
    image?: AdvertImage
    advertSummary: AdvertSummary
    paddingRight?: ResponsiveValue<SystemValue>
    imageNumber: number
}> = ({ image, advertSummary, paddingRight, imageNumber }) => {
    return (
        <Fragment>
            {image ? (
                <ResponsiveImage
                    width="50%"
                    cssHeight={{ phone: '160px', tablet: '223px' }}
                    src={image?.mainImageUrl}
                    alt={image?.description ?? advertSummary.description}
                    objectFit="cover"
                    testId={`search-result-entry-image-${imageNumber}-${advertSummary.id}`}
                    paddingRight={paddingRight}
                />
            ) : (
                <Box
                    width="50%"
                    testId={`search-result-entry-image-${imageNumber}-placeholder-${advertSummary.id}`}
                    backgroundColor="palette.babyseal"
                />
            )}
        </Fragment>
    )
}
