import React, { FunctionComponent } from 'react'
import { AdvertSummary } from '@bbx/common/types/ad-detail/AdvertSummary'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { ResultListAdInMotionLayout } from '@bbx/search-journey/sub-domains/search/components/common/result-list/AdRow/ResultListAdInMotionLayout'
import { formatAttributes } from '@bbx/common/lib/formattingAttributes'
import { AdRowAddressLine } from './AdRowAddressLine'
import { AdRowOrganisationLine } from './AdRowOrganisationLine'
import { getRealEstateAdvertPrice } from '@bbx/search-journey/common/lib/getRealEstateAdvertPrice'

interface RealEstateAdInMotionProps {
    advertSummary: AdvertSummary
    taggingData: TaggingData
}

export const RealEstateAdInMotion: FunctionComponent<RealEstateAdInMotionProps> = ({ advertSummary, taggingData }) => {
    const formattedAttributes = formatAttributes(advertSummary.attributes)
    const { mainPrice } = getRealEstateAdvertPrice(advertSummary.adTypeId, advertSummary.advertStatus, formattedAttributes)

    return (
        <ResultListAdInMotionLayout
            advertSummary={advertSummary}
            taggingData={taggingData}
            heading={advertSummary.description}
            subHeader={<AdRowAddressLine id={advertSummary.id} attributes={formattedAttributes} />}
            teaserAttributes={advertSummary.teaserAttributes}
            footer={
                <AdRowOrganisationLine
                    id={advertSummary.id}
                    name={advertSummary.advertiserInfo?.label || undefined}
                    logo={advertSummary.upsellingOrganisationLogo || undefined}
                />
            }
            price={mainPrice}
        />
    )
}
