import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { NavigatorGroupLayout } from '@bbx/search-journey/sub-domains/search/components/common/result-list/NavigatorGroup/NavigatorGroupLayout'
import { getNavigatorByIdFromGroup, isNavigatorAvailable } from '@bbx/search-journey/sub-domains/search/lib/navigator-functions'
import { Box } from '@wh-components/core/Box/Box'
import { RealEstateNavigatorDiscriminator } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/Navigators/RealEstateNavigatorDiscriminator'
import { NavigatorGroup } from '@bbx/search-journey/common/Navigators'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { SearchId } from '@bbx/common/api/dtos/search-id'

interface NavigatorGroupProps {
    group: NavigatorGroup
    onSearch: SearchCallback
    abortRequest: () => void
    searchId: SearchId
    taggingData: TaggingData
}

export const RealEstateNavigatorGroup: FunctionComponent<NavigatorGroupProps> = ({
    group,
    onSearch,
    abortRequest,
    searchId,
    taggingData,
}) => {
    const groupLabelId = group.label.replace(/\s/g, '')
    const districtNavigator = getNavigatorByIdFromGroup(group, 'district')

    return (
        <NavigatorGroupLayout
            group={group}
            groupLabelId={groupLabelId}
            navigators={group.navigatorList.filter(isNavigatorAvailable).map((navigator) => (
                <Box
                    key={navigator.id}
                    testId={`navigator-${navigator.id}-wrapper`}
                    css={css`
                        &:not(:empty):not(:last-child) {
                            margin-bottom: ${(p) => p.theme.space.m}px;

                            ${(p) => p.theme.media.tablet} {
                                margin-bottom: ${(p) => p.theme.space.s}px;
                            }
                        }
                    `}
                >
                    <RealEstateNavigatorDiscriminator
                        navigator={navigator}
                        districtNavigator={districtNavigator}
                        onSearch={onSearch}
                        abortRequest={abortRequest}
                        searchId={searchId}
                        aria-labelledby={`navigator-group-${groupLabelId}`}
                        taggingData={taggingData}
                    />
                </Box>
            ))}
        />
    )
}
