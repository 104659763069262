import React, { FunctionComponent } from 'react'
import { css } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { SpaceProps } from '@wh-components/system/space'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'

interface AdRowOrganisationLineProps {
    id: string
    name?: string
    logo?: string
}

export const AdRowOrganisationLine: FunctionComponent<AdRowOrganisationLineProps & SpaceProps> = ({ id, name, logo, ...props }) => {
    return name || logo ? (
        <Box display="flex" {...props}>
            {logo && (
                <ResponsiveImage
                    src={logo}
                    alt={`${name ?? 'Makler'} Logo`}
                    display={{ phone: 'none', tablet: 'block' }}
                    maxHeight={30}
                    maxWidth={120}
                    marginRight="s"
                    flex="0 0 auto"
                    alignSelf="flex-end"
                    testId={`search-result-entry-seller-logo-${id}`}
                    objectFit="contain"
                    css={css`
                        overflow: hidden;
                    `}
                />
            )}
            {name && (
                <Text
                    fontSize={{ phone: 'xs', tablet: 's' }}
                    color="palette.raccoon"
                    alignSelf={{ tablet: 'flex-end' }}
                    lineClamp={2}
                    testId={`search-result-entry-seller-information-${id}`}
                >
                    {name}
                </Text>
            )}
        </Box>
    ) : null
}
