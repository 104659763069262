import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import PinIcon from '@wh-components/icons/Pin'
import { AttributesList, getAttributeValue } from '@wh/common/chapter/types/Attributes'
import React, { FunctionComponent } from 'react'

interface AdRowAddressLineProps {
    id: string
    attributes: AttributesList
}

export const AdRowAddressLine: FunctionComponent<AdRowAddressLineProps> = ({ id, attributes }) => {
    const postCode = getAttributeValue(attributes.attribute, 'POSTCODE')
    const postalName = getAttributeValue(attributes.attribute, 'LOCATION')
    const street = getAttributeValue(attributes.attribute, 'ADDRESS')

    const mapCoordinates = getAttributeValue(attributes.attribute, 'COORDINATES')
    const locationQuality = getAttributeValue(attributes.attribute, 'LOCATION_QUALITY') === '1.0'
    const highlightPin = !!mapCoordinates && (locationQuality || !!street)

    const postCodePart = [postCode, postalName].filter(Boolean).join(' ')
    const address = [postCodePart, street].filter(Boolean).join(', ')

    return (
        <Box display="flex" testId={`search-result-entry-subheader-${id}`}>
            <PinIcon size="xsmall" color={highlightPin ? 'palette.primary.main' : 'palette.koala'} />
            <Text fontSize="s" color="palette.raccoon" truncate={true} aria-label={`Ort ${address}`}>
                {address}
            </Text>
        </Box>
    )
}
