import React, { FunctionComponent, RefObject, useRef } from 'react'
import { Box } from '@wh-components/core/Box/Box'
import { NavigatorGroup } from '@bbx/search-journey/common/Navigators'
import { SearchCallback } from '@bbx/search-journey/sub-domains/search/components/common/common/SearchCallback'
import { css } from 'styled-components'
import { TaggingData } from '@wh/common/chapter/types/taggingData'
import { RealEstateNavigatorGroup } from '@bbx/search-journey/sub-domains/search/components/verticals/real-estate/result-list/Navigators/RealEstateNavigatorGroup'
import { DynamicRendering } from '@wh/common/chapter/components/DynamicRendering/DynamicRendering'
import { useIsInView } from '@wh/common/chapter/hooks/useIsInView'
import { SearchId } from '@bbx/common/api/dtos/search-id'

interface NavigatorsProps {
    navigatorGroups: NavigatorGroup[]
    onSearch: SearchCallback
    abortRequest: () => void
    searchId: SearchId
    taggingData: TaggingData
    scrollRootRef: RefObject<HTMLDivElement> | undefined
}

const RealEstateNavigators: FunctionComponent<NavigatorsProps> = ({
    navigatorGroups,
    onSearch,
    searchId,
    taggingData,
    scrollRootRef,
    abortRequest,
}) => {
    // only start observing navigator groups when the filter has been in the viewport once
    // this is relevant for phone where a scrollRootRef is set (root for intersection observer) which would trigger intersection even if the filter overlay is hidden and moved to the left outside of the viewport
    // therefore 0px rootMargin
    const containerRef = useRef<HTMLDivElement>(null)
    const [containerWasInViewportOnce] = useIsInView(containerRef, '0px')

    return (
        <Box
            ref={containerRef}
            css={css`
                ${(p) => p.theme.media.tablet} {
                    > *:not(:last-child) {
                        margin-bottom: ${(p) => p.theme.space.l}px;
                    }
                }
            `}
        >
            {navigatorGroups.map((group, index) => (
                <DynamicRendering<HTMLDivElement, HTMLDivElement | undefined>
                    forceRendering={index < 2}
                    disableIntersectionChecking={scrollRootRef && !containerWasInViewportOnce}
                    key={group.label}
                    // placeholder has height for improved UX in filter sidebar on Small + for intersection observer not triggering for all placeholders at once
                    renderPlaceholder={(ref) => (
                        <Box height={200} testId={`navigator-group-${group.label.replace(/\s/g, '')}-placeholder`} ref={ref} />
                    )}
                    scrollRootRef={scrollRootRef}
                >
                    <RealEstateNavigatorGroup
                        group={group}
                        onSearch={onSearch}
                        abortRequest={abortRequest}
                        searchId={searchId}
                        taggingData={taggingData}
                        key={group.label}
                    />
                </DynamicRendering>
            ))}
        </Box>
    )
}

export const MemoizedRealEstateNavigators = React.memo(RealEstateNavigators)
